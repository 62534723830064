<template>
  <v-dialog
    id="cu-tech-user-dialog"
    v-model="CUDialogTU"
    max-width="500"
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="isButton"
        dark
        color="color_green"
        width="155"
        v-bind="attrs"
        v-on="on"
        @click="newTechUserEvent"
      >
        New API User
      </v-btn>
      <v-icon
        v-else
        small
        class="mr-2"
        v-on="on"
        @click="pencilButtonEvent"
      >
        mdi-pencil
      </v-icon>
    </template>
    <tech-user-form
      :key="componentKey"
      @closeDialog="closeDialog"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    TechUserForm: () => import('@/components/customer/tech-user/TechUserForm')
  },

  props: {
    isButton: {
      default: false,
      type: Boolean
    },
    form: {
      default: null,
      type: Object
    }
  },

  data() {
    return {
      componentKey: 0,
      CUDialogTU: false,
      item_form: null
    };
  },

  computed: {
    ...mapState(['tech_user'])
  },

  methods: {
    ...mapActions(['storeCustomerCRMID', 'storeIsModifingTechUser']),
    async newTechUserEvent() {
      this.CUDialogTU = true;
      this.emitTableItem();
      this.item_form = JSON.parse(JSON.stringify(this.form));
      this.storeCustomerCRMID(this.item_form);
      await this.storeIsModifingTechUser(false);

      this.forceRerender();
    },
    async pencilButtonEvent() {
      await this.storeIsModifingTechUser(true);
      this.item_form = JSON.parse(JSON.stringify(this.form));
      this.storeCustomerCRMID(this.item_form);
      this.CUDialogTU = true;
      this.emitTableItem();

      this.forceRerender();
    },
    emitTableItem() {
      this.$emit('toTechUserTable');
    },

    forceRerender() {
      this.componentKey += 1;
    },

    closeDialog() {
      this.CUDialogTU = false;
    }
  }
};
</script>
